import React from 'react'

const CompCBan = (props) => (
    <section id="banner" className="style2 bg-image-compc">
        <div className="inner">
            <header className="major">
                <h1>Competency C</h1>
            </header>
            <div className="content">
                <p>Demonstrate strong understanding of security and ethics issues related to informatics, user interface, and inter-professional application of informatics in specific fields by designing and implementing appropriate information assurance and ethics and privacy solutions</p>
            </div>
        </div>
    </section>
)

export default CompCBan
