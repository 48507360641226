import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import CompCBan from "../components/CompCBan";

import pic08 from "../assets/images/pic08.jpg";
import pic09 from "../assets/images/compC/data-flow-chatbot.jpg";
import pic12 from "../assets/images/compC/ccpa.jpg";

const Landing = (props) => (
  <Layout>
    <Helmet>
      <title>Competency C</title>
      <meta name="description" content="Competency A" />
    </Helmet>

    <CompCBan />

    <div id="main">
      <section id="intro">
        <div className="inner">
          <header className="major">
            <h2>Introduction</h2>
          </header>
          <p>
            At its core, informatics is a science that focuses on collecting, processing, and analyzing data to improve user experiences (SJSU iSchool, 2018). Informatics professionals work with a wide variety of data types in many different industries and must understand the security and ethical issues that govern information. Additionally, informaticists may be responsible for designing and implementing data solutions that reinforce information assurance and protect user privacy. Data professionals working in the healthcare field need to understand how to manage data and design solutions that obey certain federal laws, like the Health Insurance Portability and Accountability Act (HIPAA). Informaticists who work with website data also need to be aware of privacy laws that protect consumer information, such as the California Consumer Privacy Act (CCPA) or Europe’s General Data Protection Regulation (GDPR).
          </p>
        </div>
      </section>
      <section id="overview">
        <div className="inner">
          <header className="major-black">
            <h2>Coursework Overview</h2>
          </header>
          <p>
          Throughout the course of this program, I have had the opportunity to gain a better understanding of information assurance, ethics, and privacy solutions through class discussions and projects. INFM 214, Health Data Analytics, introduced me to the various methods of collecting health data and how this data can be analyzed to create usable information for end users. During this course, I created a project that focused on implementing artificial intelligence chatbots in urgent care websites to assist with patient questions, scheduling, and triage. An important part of this project was learning how to design a chatbot solution that protected user privacy, minimized security risks, and abided by certain laws like HIPAA. Additionally, in INFM 208: Information Security - Information Assurance, I had the opportunity to discuss various privacy laws and their applications with my classmates. I focused on discussing the CCPA law and related how this law affected my professional experiences as a web designer in California. These discussions, and my INFM 214 project, helped me develop a strong understanding of the security and ethical issues informaticists face throughout their careers, and how to design usable solutions that protect user data.
          </p>

          <div className="grid-wrapper">
            <div className="col-6">
              <div className="box">
                <h3>Evidence 1</h3>
                <p>
                  INFM 214 Project: Using natural language processing chatbots for urgent care triage and patient assistance.
                </p>
                <a href="#two" className="button">
                  View Project
                </a>
              </div>
            </div>
            <div className="col-6">
              <div className="box">
                <h3>Evidence 2</h3>
                <p>
                  INFM 208 Discussion: An in-depth look at the California Consumer Privacy Act (CCPA) and how it affects Californians.
                </p>
                <a href="#three" className="button">
                  View Project
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="two" className="spotlights">
        <section>
          <div className="inner">
            <div className="grid-wrapper">
              <div className="col-6">
                <img src={pic09} alt="Data flow diagram for a chatbot using natural language processing" className="image fit" />
                <a href="https://drive.google.com/drive/folders/19IZMipk5T7gyLJoz27OFXOlRXbCt6EMw?usp=sharing" target="_blank" className="button next">
                  View the Project
                </a>
              </div>
              <div className="col-6">
                <header className="major">
                  <h3>
                    <i class="icon fa-chart-area"></i> Evidence 1: Using Natural Language Processing Chatbots in Urgent Care Settings
                  </h3>
                </header>
                <p>
                  Information professionals must pay close attention to any laws or regulations that protect data. In the healthcare field, laws like HIPAA provide protections for patient data from healthcare fraud and abuse. INFM 214 introduced me to the field of healthcare analytics, which involves analyzing and aggregating patient data into usable information to improve patient care. During this project, I focused on designing a healthcare analytics use case solution that used natural language processing (NLP) chatbots to assist with patient questions, triage, and scheduling in urgent care facilities. Over the course of this project, I learned how NLP chatbots worked and designed a data flow chart to illustrate how NLP applications process user input. In order to prevent malicious hacking and protect user privacy, I identified security solutions that should be considered before implementing an NLP chatbot for urgent care facilities. These security measures included only using servers that are HIPAA compliant and ensuring that web implementations encrypt data and are HTTPS compliant. Additionally, I recommended that users should be able to interact with the bot without requiring a log-in or password because this would make the communication exchange less vulnerable to malicious hacking. This project allowed me to explore using NLP analytics to create a solution that would be beneficial in an urgent care setting while preserving user privacy and protecting information.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="three">
          <div className="inner">
            <div className="grid-wrapper">
              <div className="col-6">
                <header className="major">
                  <h3>Evidence 2: An in-depth look at the CCPA</h3>
                </header>
                <p>
                  Europe’s GDPR regulation and California’s CCPA law are two recent laws that strengthened consumer data protections and extended privacy rights to individuals residing in Europe and California. These laws also changed the way that websites can collect consumer data, and websites must now notify users about data collections practices and provide “opt-out” options. Information professionals who work with website data and website interfaces must understand how to design sites that are usable and comply with these laws. In INFM 208, I had the opportunity to research and write about California’s CCPA regulation and discuss my findings with my classmates. As I discussed CCPA’s requirements and privacy protections, I related the topic to my own experiences as a web developer who has transitioned websites to comply with the CCPA. These transitions included implementing “opt-out” boxes for users to choose their privacy options and creating privacy notification pages. As I discussed this topic with my classmates, I answered questions about the CCPA and learned about their experiences interacting with “opt-out” options on websites. Additionally, I also learned about other laws and regulations that affect the informatics field, including the security and ethical issues that information professionals face in different industries.
                </p>
                <p>
                  <a href="https://drive.google.com/drive/folders/1lzsytdEHGYGtouXNAhJmwOMwDcZYq6Cq?usp=sharing" target="_blank" className="button next">
                    Learn about the CCPA
                  </a>
                </p>
              </div>
              <div className="col-6">
                <img src={pic12} alt="Woman overlayed with code" className="image fit" />
              </div>
            </div>
          </div>
        </section>
      </section>
      <section id="conclusion">
        <div className="inner">
          <header className="major">
            <h2>Conclusion</h2>
          </header>
          <p>
          Understanding the security and ethics issues related to informatics are an essential part of designing and implementing data solutions. Federal and state laws regulate how data should be exchanged and protected, and information professionals must understand these regulations in order to implement appropriate information assurance and privacy solutions. In INFM 214, I created a project that focused on using NLP chatbots in urgent care websites to assist with patient questions, scheduling, and triage. As I completed this project’s use case document, I learned about security solutions that could be applied to chatbots to protect user data and prevent malicious hacking. In INFM 208, I had the opportunity to discuss the CCPA law with my classmates and share my professional experiences designing websites to comply with the law. This competency has helped me learn about the importance of security and ethics issues related to informatics and the importance of properly implement information assurance and privacy solutions in order to protect user data.
          </p>
          <div className="box">
            <h3>References</h3>
            <p>
              SJSU iSchool. (2018, October 31). Informatics Careers: What it Takes and How to Get There. https://medium.com/sjsuischool/informatics-careers-what-it-takes-and-how-to-get-there-6f98a1795bc
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default Landing;
